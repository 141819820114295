import React, { useState,useContext } from "react";
import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css"; // Import styles
import { AuthContext } from "../context/authContext";
import profileService from "../services/profile.service";
import alertService from '../services/sweetAlert';

const LyricsGenerator = () => {
  const [inputText, setInputText] = useState(""); // Hinglish Input
  const [textData, setTextdata] = useState(""); // Hinglish Input

  const [generatedLyrics, setGeneratedLyrics] = useState([]); // Stores all lines
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentLine, setCurrentLine] = useState(""); // Line being typed
  const [lineIndex, setLineIndex] = useState(0); // Track which line is being typed
  const [selectedLang, setSelectedLang] = useState("hi"); // Default to Hindi
  const { currentUser } = useContext(AuthContext);
  // Supported Indian languages
  const languages = [
    { code: "hi", label: "Hindi" },
    { code: "ta", label: "Tamil" },
    { code: "te", label: "Telugu" },
    { code: "bn", label: "Bengali" },
    { code: "kn", label: "Kannada" },
    { code: "mr", label: "Marathi" },
    { code: "gu", label: "Gujarati" },
    { code: "pa", label: "Punjabi" },
    { code: "pt", label: "Portuguese (Brazil)" },
    { code: "es", label: "Spanish" }
  ];

  // Function to fetch lyrics from API
  const fetchLyrics = async () => {
    if (!inputText.trim()) return;

    setIsProcessing(true);
    setGeneratedLyrics([]);
    setCurrentLine("");
    setLineIndex(0);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_END_POINT}/posts/generateLyrics?prompt=${encodeURIComponent(inputText)}&lang=${selectedLang}`
      );
      const data = await response.json();

      if (data.generatedLyrics) {
        // Split lyrics into lines based on proper sentence-ending characters
        const lyricsArray = data.generatedLyrics
        .replace(/\\n/g, "\n")  // Fix escaped newlines
        .replace(/।/g, "\n")    // Ensure proper sentence breaks
        .split("\n")            // Split by actual new lines
        .map(line => line.trim()) // Trim extra spaces
        .filter(line => line.length > 0); // Remove empty lines

        console.log("🎵 Generated Lyrics:", data.generatedLyrics);
        console.log("🎵 Total Lines:", lyricsArray.length);
        setTextdata(data.generatedLyrics);
        setGeneratedLyrics(lyricsArray);
        startTypingEffect(lyricsArray);
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  // Function to simulate typing effect for all lines
  const startTypingEffect = (lyricsArray) => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < lyricsArray.length) {
        setCurrentLine(""); // Reset for new line
        typeLine(lyricsArray[index], 0);
        setLineIndex(index);
        index++;
      } else {
        clearInterval(interval);
      }
    }, 3000); // Show each line every 3 seconds
  };

  // Function to animate typing effect per line
  const typeLine = (line, charIndex) => {
    if (charIndex < line.length) {
      setCurrentLine((prev) => prev + line.charAt(charIndex));
      setTimeout(() => typeLine(line, charIndex + 1), 50); // Typing speed
    }
  };

	const saveText = async () => {      
    try {
        if (generatedLyrics.length === 0) {
            alertService.error("Error!", "No lyrics available to save.");
            return;
        }

        // Convert array to a string (joining with new lines)
        const lyricsText = generatedLyrics.join("\\n");

        var insertSong = await profileService.getResult({
            qry: `CALL sp_insertPosts('${textData}','','lyrics','${currentUser?.id}','')`
        });

        console.log("✅ Lyrics saved:", insertSong);

        alertService.info(
            "Congratulations!",
            "Lyrics saved successfully!"
        );

        setTextdata("");
    } catch (err) {
        console.log("❌ Save Error:", err);
        alertService.error(
            "Error!",
            "Oops, some issue with database, please try later"
        );
    }
};


  return (
    <div style={styles.container}>
      <h2>🎶 Lyrics Generator (AI) 🎤</h2>

      {/* Language Selection Dropdown */}
      <select
        style={styles.dropdown}
        value={selectedLang}
        onChange={(e) => setSelectedLang(e.target.value)}
      >
        {languages.map((lang) => (
          <option key={lang.code} value={lang.code}>
            {lang.label}
          </option>
        ))}
      </select>

      {/* Phonetic Input Field */}
      <ReactTransliterate
        value={inputText}
        onChangeText={(text) => setInputText(text)}
        lang={selectedLang}
        placeholder="Type in English (e.g. 'maine pyar kiya')"
        style={styles.input}
      />

      {/* Generate Lyrics Button */}
      <button style={styles.button} onClick={fetchLyrics} disabled={isProcessing}>
        {isProcessing ? "Processing..." : "Generate Lyrics"}
      </button> <span className="p-1"></span>
      {generatedLyrics.length > 0 && 
      <i onClick={saveText}  className={`font-xsss text-white text-center feather-upload-cloud counter btn-round-lg bg-primary-gradiant me-1`}></i>
      }

      {/* Display Typing Effect */}
      <div style={styles.output}>
        {isProcessing && <p>⌛ Processing your lyrics...</p>}
        {generatedLyrics.length > 0 && (
          <div style={styles.lyricsContainer}>
            {generatedLyrics.map((line, index) => (
              <p key={index} style={{ opacity: index <= lineIndex ? 1 : 0.2 }}>
                {line}
              </p>
            ))}
          </div>
        )}
        {/* Show typing effect for current line */}
        {currentLine && (
          <p style={styles.typingEffect}>
            {currentLine}
            <span style={styles.cursor}>|</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default LyricsGenerator;

// 🎨 Styling
const styles = {
  container: {
    maxWidth: "600px",
    margin: "auto",
    padding: "20px",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
  },
  dropdown: {
    marginBottom: "10px",
    padding: "8px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    width: "100%",
  },
  input: {
    width: "100%",
    height: "50px",
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  button: {
    marginTop: "10px",
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#ff4081",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  output: {
    marginTop: "20px",
    fontSize: "18px",
    fontWeight: "bold",
  },
  lyricsContainer: {
    textAlign: "left",
    marginTop: "10px",
    fontSize: "18px",
    color: "#333",
  },
  typingEffect: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#222",
  },
  cursor: {
    display: "inline-block",
    width: "10px",
    animation: "blink 1s infinite",
  },
};
