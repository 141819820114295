import React, { Fragment, useContext, useState,useEffect } from "react";
import LyricsGenerator from "../components/LyricsGenerator";


export default function Composition() {
    const queryParams = new URLSearchParams(window.location.search)
    let linkType = window.location;

    return (
        <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
        <Fragment>
            <LyricsGenerator></LyricsGenerator>

            </Fragment>
            </div>
            </div>
            </div>
    )
};