import React, {Fragment} from "react";


function Privacy() {


  return (
    <Fragment>
         <div className="main-content bg-white ">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
  <h2>Privacy Policy</h2>   
  <p>
  We know that in this digital age, your privacy is important. This Privacy Policy reflects our commitment to protecting personal data and the choices we offer you regarding how your data is used. We welcome you to read more about how we keep your information safe, as well as how you can exercise your rights. In addition, our Privacy policy covers our treatment of data that may be personal to you.
‍We will review, update, and amend these policies from time to time, consistent with our business needs and technology. We encourage you to check back periodically for new updates or changes. Your continued use of the service makes up for your acceptance of any change to this Privacy Policy. We are the data controller of your information. We handle and process all data on behalf of our customers
You may likewise decide not to give us "discretionary" Personal Data; however, please remember that without it, we will most likely be unable to provide you with the full scope of our administrations or with the best client experience when utilizing our Services.
  </p> 

<p>
This Privacy Policy ("Privacy Policy") describes how <strong>Geet Suhane (geetsuhane.com)</strong>. Will gather, use, and maintain your Personal Information on geetsuhane.com. It will also explain your legal rights with respect to that information.
</p>
<p>
By using the Geet Suhane website or services, you confirm that you have read and understood this Privacy Policy and our Terms (together referred to herein as the "Agreement"). The agreement governs the use of geetsuhane.com. Geet Suhane will collect, use, and maintain information consistent with the agreement.
</p>

<p>
Our app requests access to your device’s camera in order to allow you to take photos and videos within the app. 
We do not store or share your photos and videos with third parties without your consent. We are committed to protecting your privacy and complying with applicable data protection laws.
</p>

<p>
What private data do we collect from the people who visit our website?
</p>
<p>
When enlisting on our website, as suitable, you could be approached to type in your name, email, or different subtleties to assist you with your experience.
</p>
<p>

When you create an account and use the Services, including through a third-party platform, we collect any data you provide directly, including:

Account Data: To use certain features, you need to create a user account. When you create or update your account, we collect and store the data you provide, like your email address, password,name, picture,location and assign you a unique identifying number ("Account Data").
</p>

<p>

Location Data: this is information that is derived from your GPS, Wi-Fi, compass, accelerometer, IP address, or public posts that contain location information. Location information will be disclosed (either to us, to other users, or both):


When you use location-based features, such as People Nearby, POI search, and when you share your location with other geetsuhane.com users; and <br/>
When you access geetsuhane.com, we derive location information from your IP address, device, or internet service to prevent multiple or fraudulent logins to your account.<br/>
<br/>Personal Data: Personal Data is information that can be used to identify you specifically, including your name, zip code, time zone, email address, telephone number, or demographic information like your age, gender, or hometown. 
<br/>You consent to give us this information by providing it to us voluntarily on our mobile application. You provide some of this information when you register with or make purchases from our website. You may also provide this information by participating in various activities associated with our website, including responding to blogs, contacting us with questions, or participating in group training. Your decision to disclose this data is entirely voluntary. You are under no obligation to provide this information, but your refusal may prevent you from accessing certain benefits from our website.
<br/>Financial Data: Financial data is related to your payment methods, such as credit card or bank transfer details. We collect financial data to allow you to purchase or exchange services from our website. We store limited financial data. Most financial data is transferred to our payment processor, PayPal, and you should review these processors' Privacy Policies to determine how they use, disclose, and protect your financial data. 
<br/>As a courtesy, PayPal Privacy Policy can be found here https://www.paypal.com/us/webapps/mpp/ua/privacy-full
<br/>Profile Data: You can also choose to provide profile information like a photo,  social media profiles, or other data. Your Profile Data will be publicly viewable by others.
<br/>Shared Information: is information about you or relating to you that is voluntarily shared by you on geetsuhane.com. Shared Information may include postings that you make on geetsuhane.com (including your public profile, the lists you create, and photos, videos, and voice recordings as accessed with your prior consent through your device's camera and/or microphone sensor), any postings from others that you re-post and Location Data and Log Data associated with such postings. Shared Information also includes information about you (including Location Data and Log Data) that others who are using geetsuhane.com share about you.

</p>
<p>
Data About Your Accounts on Other Services: 
<br/>We may obtain certain information through your social media or other online accounts if they are connected to your geetsuhane.com account. If you login to via Facebook or another third-party platform or service, we ask for your permission to access certain information about that other account. For example, depending on the platform or service, we may collect your name, profile picture, account ID number, login email address, location, the physical location of your access devices, gender, birthday, and list of friends or contacts.
<br/>Social Networking Data: We may access personal information from social networking sites and apps, including Facebook, and Google, which may include your name, your social network username, location, email address, age, gender, profile picture, and any other public information. If you do not want us to access this information, please go to the specific social networking site and change your privacy settings.
<br/>Mobile Device Data: If you use our website via a mobile device or website, we may collect information about your mobile device, including device ID, model and manufacturer, and location information.
<br/>Those platforms and services make information available to us through their APIs. The information we receive depends on what information you (via your privacy settings) or the platform or service decide to give us.

</p>
<p>

When do we acquire information? <br/>
<br/>We get data from you when you get enlisted on our website, respond to an audit, give us reactions on our items or enter information on our website. Below are the examples:
<br/>If you register to become a user of Our Services.
<br/>If you send Us a message through Our Services or correspond with Us by telephone in writing or otherwise.
<br/>As you visit our website.
<br/>If you subscribe to one of Our Services.
<br/>Participate in a discussion board or other social media functions on Our Services.
<br/>If you enter a competition, promotion, or survey.
<br/>If you report a problem to us; and
<br/>from any of your activities in connection with your use of Our Services.
<br/>Register on our website to get updated about a new post.
<br/>To deal with our relationship with you, which will include Notifying you about changes to our terms or security arrangement. Requesting that you leave an audit or take an overview. 
<br/>To send you our email newsletter and other automated email correspondences. 
<br/>To make proposals and suggestions to you about merchandise or administrations that might hold any importance to you.
<br/>Automatically collected information about your use of our Services or tools,
<br/>This information is registered automatically with the visit by the own configuration or manual of each tool on the website
<br/>At the point when you visit, connect with, or utilize our Service, we may gather, record, or create certain specialized data about you. We do so either autonomously or with the assistance of third-gathering Service Providers, including using "cookies" and other following innovations. 

</p>

<p>
Such data comprises availability, specialized and collected utilization data, for example, IP locations and general areas, gadget data (like sort, working framework, cell phone id, program form, region and language settings, Browser Information, Geographic location, Time of Visit, Referring site, applications or service, search engine utilized), date and time stamps of use, the and pixels introduced or used on such gadget and the recorded movement (meetings, clicks and different cooperation's) of Visitors and Users regarding our Service. for purposes including examination, service-, tasks, and business quality control and enhancements, and record-keeping purposes.
This is for the purpose of improving the website, services, and security, among which we include security inspection by the administration of the website and third parties.
How do we handle social signals? 
If you choose to register or log in to our website using a social media account, we may access certain information about you. 
Our Sites offer you the ability to register and log in using your third-party social media account details (like your Facebook or Google logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile Information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile picture, as well as other information you choose to make public. 
We will use the information we receive only for the purposes described in this privacy policy or that are otherwise made clear to you on the Sites. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy policy to understand how they collect, use, and share your personal information and how you can set your privacy preferences on their sites and apps.
The APIs that we use to store and access cookies and other information on your devices. If you are a user currently in the European Union, please look at our EU User Consent Policy.
Do Not Track
Currently, various browsers — such as Internet Explorer, Firefox, and Safari — offer a "do not track" or "DNT" option that relies on a technology known as a DNT header, which sends a signal to Web sites visited by the user about the user's browser DNT preference setting. geetsuhane.com does not currently commit to responding to browsers' DNT signals with respect to the Company's Web sites, in part because no common industry standard for DNT has been adopted by industry groups, technology companies, or regulators, including no consistent standard of interpreting user intent. geetsuhane.com takes privacy and meaningful choice seriously and will make efforts to continue to monitor developments around DNT browser technology and the implementation of a standard.
How do we use your details?
We may utilize the data we procure from you when you enlist, make a buy, join our bulletin, respond to an examination or promoting correspondence, peruse the site, or utilize specific other site includes in the following ways:
Create your account; and
Correspond with you; and
Compile anonymous statistical data for our own use or for a third party's use; and
Assist law enforcement as necessary; and
Prevent fraudulent activity on our website or mobile app; and
Analyze trends to improve our website and offerings.
<br/>To fulfill or meet the reason you provided the information (e.g., to help provide our Site services to you).
<br/>To personalize and develop our site and the services we provide you and improve our offerings.
<br/>To provide certain features or functionality of the services on the site.
For marketing and promotions.
<br/>To create, maintain, customize, and secure your account with us.
<br/>To personalize your experience and deliver content and services relevant to your interests.
<br/>To help maintain the safety, security, and integrity of our site, services, databases, and other technology assets and business.
For testing, research, analysis, and including to develop and improving our site and services.
Respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.
To prevent illegal activity, fraud, and abuse.
Where we must play out the agreement we are going to go into or have gone into with you
To help our site, we will be ready to serve you better.
<br/>Do we use 'cookies?
Yes. Cookies are small documents a site or its provider exchanges to your computer's hard drive through your browser (if you allow) that permit the site's or service provider's systems to identify your internet browser and capture, please remember, certain information. Also, they are used to help us understand your requirements based on prior or current site activity, which permits us to offer you improved services. We also use cookies to help us put together aggregate data about site traffic and site conversation so that people may offer better site experience and tools in the foreseeable future.
How we use cookies
We use cookies and similar technologies for several purposes, depending on the context or service, including: 
Recognize you if you sign in to use our offerings. This allows us to provide you with recommendations, display personalized content, and provide other customized features and services.
We are keeping track of your specified preferences. This allows us to honor your likes and dislikes, such as your language and configuration preferences.
Detecting and preventing fraudulent activity and improving security.
We are conducting research and diagnostics to improve our offerings.
Reporting allows us to measure and analyze the performance of our offerings.
First-party cookies
Strictly Necessary Cookies: These cookies are necessary for the website to function and cannot be switched off in our systems. They are often set in response to actions made by you, which amount to a request for services, such as setting your privacy preferences, logging in, or filling in forms.
Functional cookies: These cookies enable the website to provide enhanced functionality and personalization. They may be set by third party providers whose services we have added to our pages or by us.
Performance cookies: These cookies allow us to count visits and traffic sources to measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site.
Targeting cookies: These cookies may be set through our site and used to build a profile of your interests and show you relevant adverts on other sites.
Third-party cookies
In addition to our cookies, we may also use various third-party cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on. These cookies may be set through our site by our advertising partners. Those companies may use them to build a profile of your interests and show you relevant adverts on other sites.
Google Analytics Cookies
We use Google Analytics to investigate the utilization of our site by users and visitors. Google Analytics assembles data about site use through cookies. The data assembled identifying our site is utilized to make reports about the utilization of our site.
You can refuse the use of Google Analytics by clicking on the following link. An opt-out cookie will be set on the computer, which prevents the future collection of your data when visiting this website: Disable Google Analytics.

</p>
<p>
What are your choices regarding cookies?
Cookie Preferences on the website: Our cookies allow you to take advantage of some essential and useful features. Blocking some types of cookies may impact your experience of our sites. You can change your cookie preferences at any time by clicking 'Cookie Preferences in the footer of the website.
Browser settings: You can also manage browser cookies through your browser settings. The 'Help' feature on most browsers will tell you how to remove cookies from your device, prevent your browser from accepting new cookies, how the browser notify you when you receive a new cookie, how to disable cookies, and when cookies will expire. Check the support site for your browser to understand the privacy settings available to you. If you block or reject some of our cookies through your browser's settings, you might not be able to use certain offerings that require you to sign in to an account, and some features and services may not work. You might also have to manually adjust your preferences or settings every time you visit our website.
California Consumer Rights
The California Consumer Privacy Act provides specific rights to those in California. Suppose you are a California-based consumer, as that term is defined under California law. In that case, this section shall apply in addition to all other applicable rights and information contained in this Statement.
You have the right to request that we provide you with what personal information we collect, use, and disclose.
You have the right to request that we delete the personal information we, or our service providers, store about you. 
We will not discriminate or retaliate against you if you elect to exercise any rights under this section of our Privacy Statement. 
You may request that we not sell your personal information. As noted above, we do not sell your personal information, and we only share your personal information with third parties, as described in this Statement.
You have the right to designate an authorized agent to request on your behalf. Please see the Identity Verification Requirement below for information on our process for verifying that we have received a legally valid request.
If you are a California consumer and have additional questions based on this section of our Privacy Statement or wish to submit a request to request that we not share your information with third parties, please contact us by email or through the contact us page.
See more at https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
According to caloppa, we agree to the following:
Users can visit our site anonymously.
You'll be notified of any ONLINE PRIVACY POLICY changes via email
Coppa (children online privacy protection action)
With regards to the assortment of private information from children under the age of 13 years, the Children's Online Privacy Protection Act (COPPA) puts parents in charge. The Federal Trade Commission, United States consumer safety firm, enforces the COPPA Guideline, which defines what providers of websites and online services should do to safeguard children's privacy and security online. For more details, Click Here or below link
https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule 
GDPR-Customer data processing appendix:
Customer Data" means any personal data that Geet Suhane processes on the Customer's behalf via the Services, as more particularly described in this DPA.
"Data Protection Laws" means all data protection laws and regulations applicable to a party's processing of Customer Data under the agreement, including, where applicable, EU Data Protection Law and Non-EU Data Protection Laws.
GDPR-EU data protection law  
"EU Data Protection Law" means all data protection laws and regulations applicable to Europe, including (i) Regulation 2016/679 of the European Parliament and the Council on the protection of natural persons concerning the processing of personal data and on the free movement of such data (General Data Protection Regulation) ("GDPR "); (ii) Directive 2002/58/EC concerning the processing of personal data and the protection of privacy in the electronic communications sector; (iii) applicable national implementations of (i) and (ii); and (iv) in respect of the United Kingdom ("UK ") any applicable national legislation that replaces or converts in domestic law the GDPR or any other law relating to data and privacy as a consequence of the UK leaving the European Union.
"Europe" means, for this DPA, the European Union, the European Economic Area and/or their member states, Switzerland, and the United Kingdom.
"Non-EU Data Protection Laws" means the California Consumer Privacy Act ("CCPA"); the Canadian Personal Information Protection and Electronic Documents Act ("PIPEDA"); and the Brazilian General Data Protection Law ("LGPD "), Federal Law no. 13,709/2018.
"SCCs" means the standard contractual clauses for processors as approved by the European Commission or Swiss Federal Data Protection Authority (as applicable), which shall be applied only to transfers of Customer Data from the European Union.
"Services Data" means any data relating to the Customer's use, support, and/or operation of the Services, including information relating to volumes, activity logs, frequencies, bounce rates, or other information regarding emails and other communications the Customer generates and sends using the Services.
Parties' roles: If EU Data Protection Law or the LGPD applies to either party's processing of Customer Data, the parties acknowledge and agree that concerning the processing of Customer Data, the Customer is the controller and is a processor acting on behalf of the Customer, as further described in Annex A (Details of Data Processing) of this DPA.
Purpose limitation: Geet Suhane shall process Customer Data only following the Customer's documented lawful instructions as outlined in this DPA, as necessary to comply with applicable law, or as otherwise agreed in writing ("Permitted Purposes"). The parties agree that the agreement sets out the Customer's complete and final instructions to getting Suhane concerning the processing of Customer Data. Processing outside the scope of these instructions (if any) shall require a prior written agreement between the parties.
Prohibited data. The Customer will not provide (or cause to be provided) any Sensitive Data to Geet Suhane for processing under the agreement and Geet Suhane will have no liability whatsoever for Sensitive Data, whether in connection with a Security Incident or otherwise. To avoid doubt, this DPA will not apply to Sensitive Data.
Customer compliance: Customer represents and warrants that (i) it has complied, and will continue to comply, with all applicable laws, including Data Protection Laws, in respect of its processing of Customer Data and any processing instructions it issues to Geet Suhane; and (ii) it has provided, and will continue to provide, all notice and has obtained, and will continue to obtain, all consents and rights necessary under Data Protection Laws for Geet Suhane to process Customer Data for the purposes described in the agreement. The customer shall have sole responsibility for the accuracy, quality, and legality of Customer Data and how the Customer acquired Customer data. Without prejudice to the generality of the preceding, the Customer agrees that it shall be responsible for complying with all laws (including Data Protection Laws) applicable to any emails or other content created, sent, or managed through the service, including those relating to obtaining consents (where required) to send emails, the content of the emails and its email deployment practices.
<br/>The lawfulness of Customer's instructions: The Customer will ensure that United Kingdom processing of the Customer Data by the Customer's instructions will not cause Geet Suhane to violate any applicable law, regulation, or rule, including, without limitation, Data Protection Laws. Geet Suhane shall promptly notify the Customer in writing unless prohibited from doing so under EU Data Protection Laws if it becomes aware or believes that any data processing instruction from the Customer violates the GDPR or any UK implementation of the GDPR.
<br/>Your Legal Rights
Under certain circumstances, you have rights under data protection laws to your data.
<br/>You may have the following rights: -
<br/>Request access to your data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and check that we are lawfully processing it.
Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide.
<br/>Request deletion of your data. This enables you to ask us to delete or remove personal data where there is no good reason to continue processing it. You also have the right to ask us to delete or remove your data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons, which will be notified to you, if applicable, at the time of your request.
Object to processing your data where we are relying on a legitimate interest (or those of a third party). Something about your situation makes you want to object to processing on this ground as you feel it impacts your fundamental rights and freedoms. You also have the right to object to processing your data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information, overriding your rights and freedoms.
R<br/>equest restriction of processing of your data. This enables you to ask us to suspend the processing of your data in the following scenarios:
<br/>If you want us to establish the data's accuracy.
<br/>Our use of the data is unlawful, but you do not want us to erase it.
<br/>You need us to hold the data even if we no longer require it as you need it to establish, exercise, or defend legal claims.
<br/>You have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.
Request the transfer of your data to you or a third party. We will provide your data in a structured, commonly used, machine-readable format to you or a chosen third party. Note that this right only applies to automated information; you initially provided consent for us to use or use the information to perform a contract with you.
<br/>Withdraw consent at any time where we are relying on consent to process your data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not provide certain services to you.
International Data Transfer <br/>
<br/>The personal information we process may be transmitted or transferred to countries other than where you reside. Those countries may have data protection laws that are different from the laws of your country.
The servers for our platform are in the United States and the Geet Suhane, and third-party service providers operate in many countries around the world. When we collect your personal information, we may process it in any of those countries.
We have taken appropriate steps and put safeguards in place to help ensure that your personal information remains protected by this Privacy Statement. For example, any data transfers between our group companies are governed by our intragroup agreements, which incorporate strict data transfer terms (including the European Commission's Standard Contractual Clauses for transfers from the EEA) and require all group companies to protect the personal information they process in accordance with applicable data protection law.
<br/><br/>We also require that third-party service providers to whom a data transfer is made have appropriate safeguards in place to protect your personal information in compliance with applicable data protection law. The measures used will depend on the service provider, and our agreements with them may include European Commission-approved Standard Contractual Clauses, the service provider's certification under the EU-U.S. and/or Swiss-U.S. Privacy Shield certification, or reliance on the service provider's Binding Corporate Rules, as defined by the European Commission.
<br/>How do we protect your details?
<br/>We have implemented industry-accepted administrative, physical, and technology-based security measures to protect against the loss, misuse, unauthorized access, and alteration of personal information in our systems. We ensure that any employee, contractor, corporation, organization, or vendor who has access to personal information in our systems are subject to legal and professional obligations to safeguard that personal information.
We do not use vulnerability scanning and/or scanning to PCI specifications.
<br/>We use regular Malware Scanning.
Your individual information is comprised of secured systems and is merely accessible by a restricted number of folks who've special access privileges to such systems and must keep the information confidential carefully. Furthermore, all very sensitive/credit information you resource is encrypted via Secure Socket Layer (SSL) technology.
<br/>We implement several security measures whenever a user gets into, submits, or accesses their information to keep up the protection of your individual information.
While we strive to use commercially acceptable means to protect your personal information, no method of transmission over the Internet or form of electronic storage is 100 percent secure. Therefore, we cannot guarantee its absolute security.
<br/>geetsuhane.com prohibits unauthorized access or use of personal information stored on our servers. Such access is a violation of law, and we will fully investigate and press charges against any party that has illegally accessed the information within our systems.
Can-spam act
<br/>The CAN-SPAM Act is a regulation that sets the guidelines for commercial email, establishes requirements for commercial announcements, offers recipients to have emails ceased from being delivered to them, and spells out hard fines for violations.
<br/>We accumulate your email to be able to:
<br/>Send information, react to questions, and/or other demands or questions
<br/>To maintain compliance with CANSPAM, we consent to the next:
<br/>Do not use untrue or misleading subject matter or email addresses.
<br/>Identify the concept as an advertisement in some realistic way.
<br/>Include the physical address of our site headquarters or business
Screen third-party email marketing services for conformity, if one can be used.
Honor opt-out/unsubscribe demands quickly.
<br/>Allow users to unsubscribe utilizing the link at the bottom of every email.
If anytime you want to unsubscribe from receiving future emails, you can email us by using the contact form at our website geetsuhane.com, and we'll immediately remove you from ALL communication.
<br/>Limitation of liability
Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so some of the above limitations may not apply to you.
We make no legal representation that the website or services are appropriate or available for use in locations outside the United States. You may access the website from outside the United States. At your own risk and initiative and must bear all responsibility for compliance with any applicable foreign laws.
Governing Law and Jurisdiction
This website originates from the United States. The laws of the United States. Without regard to its conflict of law, principles will govern these terms to the contrary. You hereby agree that all disputes arising out of or in connection with these terms shall be submitted to the exclusive jurisdiction of the United States. By using this website, you consent to the jurisdiction and venue of such courts in connection with any action, suit, proceeding, or claim arising under or by reason of these terms. You hereby waive any right to trial by jury arising out of these terms.
Changes to this privacy notice
We reserve the right to alter this privacy notice at any time. Such alterations will be posted on our website. You can also obtain an up-to-date copy of our privacy notice by contacting us.
Contacting us  <br/>
If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may do so via the contact us or email us at support@geetsuhane.com.  
This document was last updated on June 1, 2023
</p>

      </div>
      </div>
      </div>
      </div>
    </Fragment>
  );
}

export default Privacy;
