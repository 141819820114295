import React, { Component , Fragment,useCallback,useContext,useRef } from "react";


import {  animated,useSpring } from 'react-spring';
import Profiledetail from '../components/Profiledetail';
import Profilephoto from '../components/Profilephoto';
import ProfilecardThree from '../components/ProfilecardThree';
import Createpost from '../components/Createpost';
import Appfooter from "../components/Appfooter";
import UserProfile from "../components/UserProfile";
import Load from '../components/Load';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { useState } from "react";
import profileService from "../services/profile.service";
import Postview from "../components/Postview";
import { PostsContext } from "../context/postsContext";
import Drawer from 'react-bottom-drawer';
import alertService from '../services/sweetAlert';
import { Link } from "react-router-dom";
import Gifts from "../components/Gifts";
import Marquee from "react-fast-marquee";
import { refEqual } from "firebase/firestore/lite";
import Youtube from "../components/Youtube";
import axios from "axios";


// import Moments from "./Moments";

export default function Userpage() {
  const postRef = useRef(null);
  const likesRef = useRef(null);
  const commentsRef = useRef(null);
  const humorRef = useRef(null);
  const shareRef = useRef(null);
  const chatRef = useRef(null);

  const navigate = useNavigate()
  const [gold, setGold] = useState(true);
    const [moments, showMoments] =useState(false);
    const { currentUser,getUserDetails,userData } = useContext(AuthContext);
    const [user, setUser] = useState(null);
    const [points, setPoints] = useState(null);
    const [dailyPoints, setdailyPoints] = useState(null);
    var [topList, setList]= useState(null);
    const cdn = 'https://files.geetsuhane.com/';
    const ssd = process.env.REACT_APP_API_END_POINT+'/gsfiles/';
    const [src, setSrc] = useState();
    const queryParams = new URLSearchParams(window.location.search)
    const term = queryParams.get("uid")
    const [isVisible, setIsVisible] = useState(false);
    const [giftItem, setgiftItem] = useState(0); 
    const [rewards, setRewards] = useState(null); 
    const [weeklyrewards, setWeeklyRewards] = useState(null);
    const openDrawer = useCallback(() => setIsVisible(true), [])
    const closeDrawer = useCallback(() => {
      setIsVisible(false);
    }, []);
    const [cash,setCash] =useState(null);
    const [more, setMore]=useState(true);
    const [posts,setPosts] = useState([])

    const getProfilePosts = async (pcount,start,end,uid) => {
 
      const query= `CALL sp_getProfilePosts(${pcount},${start},${end},'${uid}');`
          
      const postData = await axios.post(`${process.env.REACT_APP_API_END_POINT}/profile/getResult`, { qry: query });

      if (postData?.data?.[0]) {
        const newPosts = postData.data[0];
      
        if (start === 0) {
          // If starting fresh, replace the existing posts
          setPosts(newPosts);
        } else {
          // Append new posts to the existing ones
          setPosts(prevPosts => [...prevPosts, ...newPosts]);
        }
      }
    }
      

    const [state, setState] = useState({
      isPaneOpen: false,
      isPaneOpenBottom: false,
    });

    const props = useSpring({
      from: { transform: "translateY(20px)" },
      to: [{ transform:  "translateY(-20)"}],           
      config: { duration: "180" },
      loop: true,
      //reset: true
    });

    const getPoints = async () => {    
 
      var pcounts = ""
      const refid = term?term: currentUser?.id;
      pcounts = await profileService.getResult({
        qry :`CALL sp_getPoints('${refid}')`
      });
      setPoints(pcounts?pcounts[0][0]:null);
    };

    const  entryEffect = async() => {
      const imageUrl = cdn+"/promo/biren.gif";
      // setInterval(() => {
        //setSrc(imageUrl);
      // }, 5000);
    }
    const getDailyPoints = async () => {    
 
      var pcounts = ""
      const refid = term?term: currentUser?.SID;
      console.log(refid);
      const userId = currentUser?.SID === '008' && term ? userData?.id : refid;
      console.log(userId);
      pcounts = await profileService.getResult({
        qry :`CALL sp_dailyPoints('${refid}')`
      });
      setdailyPoints(pcounts?pcounts?.[0][0]:null);
       //console.log(pcounts?.[0][0])
     
      if(!term || currentUser?.SID==='008') {
       //console.log(refid)
        pcounts = await profileService.getResult({
          qry :`CALL getRewardStatus('${refid}')`
        });
        setRewards(pcounts?.[0][0])
        //console.log(pcounts)

        pcounts = await profileService.getResult({
          qry :`CALL sp_getWeeklyrewardStatus('${refid}')`
        });
        setWeeklyRewards(pcounts?.[0][0])

      }

      
     
       //console.log(getCash);
         
     }
     const withdraw = async(rs) => {
      if(rs<1000) {
        alertService.info(
          'Withdraw',
          'You need minimum Rs 1000 to withdraw, terms and consitions apply!'
        ) 
      } else {
        alertService.info(
          'Withdraw',
          'Please send your wihdrawal request to GS whatsup# +1 332 287 8300'
        ) 
      }
    

  };
     const getCash = async() => {
      const refid = term?term: currentUser?.id;
        var cashval = await profileService.getResult({
            qry: `CALL sp_getIncome('${currentUser?.SID==='008' && term?userData?.id:refid}');`
        });

       setCash(cashval?cashval[0][0]:0);
      
    };
    const refid = term?term: currentUser?.SID;

    var getGold = async () => {
      var goldStatus =await  profileService.getResult({
          qry: `CALL sp_getGoldBalance('${refid}');`
      });
     setGold(goldStatus?.[0][0]?.gold);
     //console.log(goldStatus)
   //localStorage.setItem("gold",goldStatus?.[0][0]?.gold)
       
   }
  
    const sendGift = (item,uid) => {
      openDrawer();
      localStorage.setItem("item", item);
      //localStorage.setItem("giftuser", usr);
      localStorage.setItem("giftsource", 'post');
      localStorage.setItem("uid",uid);
      setState({ isPaneOpenBottom: true });
      }  

      const  getCoins = async(coins,src) => {
        //console.log(coins)
       // console.log(dailyPoints?.share)
       alertService.info(
        'Auto collect',
        'Rewards will be auto collected, no need to collect manually'
      ) 
      return;

        if(coins===0) {
          alertService.error(
            'Rewards',
            'Yo have not completed all activities yet or you have already collected rewards :)'
          ) 
          return;
        }
        postRef.current.disabled = true;
        chatRef.current.disabled = true;
        likesRef.current.disabled = true;
        commentsRef.current.disabled = true;
        shareRef.current.disabled = true;
        humorRef.current.disabled = true;

        const pupdate = await profileService.getResult({
          qry :`CALL sp_updateGold('${currentUser?.id}',${coins},0,'${src}','dailytask')`
        });
        alertService.info(
          'Rewards',
          'Coins will be added after verification!'
        ) 
        await getCash();
        await getDailyPoints();
        postRef.current.disabled = false;
        chatRef.current.disabled = false;
        likesRef.current.disabled = false;
        commentsRef.current.disabled = false;
        shareRef.current.disabled = false;
        humorRef.current.disabled = false;
      }
      function loadMorePosts(){
        showMoments(true)
        setMore(true);
        getProfilePosts(20,posts?.length?posts[posts?.length-1]?.id:0,0,refid);
        setMore(false);
      }

     
       
  useEffect(() => {
  //   setTimeout(() => {
  //     setProcessing(false);
  // }, 5000);
    //if(localStorage.getItem('userAuth')) {
    setUser(currentUser);
      async function fetchList() {        
        //await getPoints();
        await getCash();
        await getDailyPoints();
        await getGold();        
        await getUserDetails(refid);
        await loadMorePosts(20,posts?.length?posts[posts?.length-1]?.id:0,0,refid);
      } 
      fetchList();
      
   // } else {
    // if(currentUser==null) {
    //   localStorage.setItem("redirect", "/userpage")
    //   navigate('/login')
    // } 

  },[currentUser,refid]);

 
  return (
          <Fragment>
           
            <div className="main-content right-chat-active">
           
              <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0">
                  <div className="row">
                    <div className="col-xl-12 mb-3  position-relative">
                      <div className="h-30 bg-red-gradiant text-white strong">
                        {userData?.title && (
                          <Marquee><i className="feather-award blink-n"></i> {userData?.title}</Marquee> 
                        )

                        }
                      
                    
                     {term?.length>0? (
                       <UserProfile onClick={()=> showMoments(true)} />
                     ): (
                      <ProfilecardThree onClick={()=> showMoments(true)} />
                     )

                     }
                    
                     
                      {/* <center>
                      {(processing && currentUser?.id==="40b7b63d-946b-4d3a-954f-2aa4bc331ce6" || term==="40b7b63d-946b-4d3a-954f-2aa4bc331ce6")? (
                    
                    <animated.div style={props}>
                <img  src={cdn+"/promo/biren.gif"} alt="scenery" height="100%" width="100%" />
                     </animated.div>
         
               ):''}
                      </center> */}
                   
                   
                      <div className="mt-0 pt-1 bg-gold-gradiant">
                      <marquee  className="font-xsss text-white" direction="left">

                      <span className="text-blue-500 mt-0   strong">Daily Activities: <span className="text-white">
                       {dailyPoints?.activity}
                        /10000</span> </span>
                        <span className="pl-10x"> Sargam:<span > {dailyPoints?.freestyle}/10K</span> </span>
                                    <span className="pl-10x"> Youtube Post:<span > {dailyPoints?.posts}/1</span> </span>
                                    <span className="pl-10x"> Likes<span > {dailyPoints?.likes}/10</span> </span>
                                    <span className="pl-10x"> Comments:<span > {dailyPoints?.comments}/5</span> </span>
                                    <span className="pl-10x"> Share:<span > {dailyPoints?.shares}/2</span> </span>                                  
                                    {/* <span className="pl-10x">Boat Race Played:<span > {dailyPoints?.brp}/1</span> </span> */}
                                    <span className="pl-10x">Cafe:<span > {dailyPoints?.gsc}/10</span> </span>
                                    <span className="pl-10x">DW:<span > {dailyPoints?.dw}/10</span> </span>
                                    <span className="pl-10x">GSP:<span > {dailyPoints?.gsp}/10</span> </span>
                                    <span className="pl-10x">Chess:<span > {dailyPoints?.brj}/10</span> </span>
                                    <span className="pl-10x">Gifts:<span > {dailyPoints?.gifts}/5</span> </span>


                                    {/* <span className="pl-10x"> Votes:<span > {dailyPoints?.votes}/10</span> </span> */}
                                   

                      <span className="text-blue-500 mt-0   strong pl-10x">Monthly Activities: <span className="text-white">{dailyPoints?dailyPoints.monthly_points:'loading...'}</span> </span>
                      <span className="text-blue-500 mt-0   strong pl-10x">EXP: <span className="text-white">{userData?userData.exps:'loading...'}</span> </span>
                     </marquee>

                        </div>
                       
                      </div>
                      <Appfooter first="home"></Appfooter>
                      <div className="p-1"></div>
                      <Profiledetail className="fw-500 text-grey-500 lh-24 font-xssss mb-0 mx-1"
                        user={user}
                      />  
                    </div>
                    <div className='clearfix text-center'><a href="https://amzn.to/3V6iph4">Amazon Hot Deals</a></div>
                    <div className="row">
                                    <div className="col-lg-5 mb-3">
                                    {(!term || currentUser?.SID==='008') && currentUser &&  (
                                        <div className="table-content table-responsive">
                                         
                                        <div className="font-xsss text-center strong">Daily, Weekly and Monthly Rewards</div>
                                        <div className="text-center"> <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w25 rounded-3 " /> <span className="font-xssss  strong "> Available GS Gold: {gold}</span> </div>
                                        <div className="text-center"> <img src="https://files.geetsuhane.com/home/points.png" alt="GS Points" className="w25 rounded-3 " /> <span className="font-xssss  strong "> Monthly Points: {dailyPoints?.monthly_points}</span> </div>
                                        <div className="text-center"> <img src="https://files.geetsuhane.com/gifts/gscash.jpg" alt="GSC" className="w25 rounded-3 " /> <span className="font-xssss  strong "> GS Rewards: {cash?.rupee}</span> 
                                       
                                    

                             <Link to={`/redeem`} className="mt-0 btn pt-1 pb-2 ps-3 pe-3 ms-1 ls-3 d-inline-block  bg-mini-gradiant font-xsssss fw-700  text-white text-center">Redeem</Link>
                           
                             <div className="font-xssss">Only active users can redeem upto 50% GSC after GS Managemnet's approval, 
                              50% will be resevred. Terms and conditions apply. GS can decline the request without any prior notice. Terminated users will loose all GSC automatically.</div>
                                        </div>

                          

                                            <hr className="w-100"></hr>
                                           
                                            <table className="table text-center">
                                               
                                                <tbody>

                                                <tr>
                                                    <td className="product-thumbnail text-left ps-0">
                                                  
                                                            <div className="strong font-xsssss text-gray">
                                                            <Link to="/va"> {dailyPoints?.freestyle}/5K Sargam</Link>                                                             
                                                              </div> 
                                                            <div className=" font-xsssss text-red">  (Max 5K points )</div> 
                                                            
                                                        </td> 
                                                        <td className="product-thumbnail text-left ps-0">
                                                        <span className=" font-xsssss text-gray">5K</span> 
                                                            <img src="https://files.geetsuhane.com/home/points.png" alt="GS Points" className="w25 rounded-3" />
                                                            
                                                        </td>                                                   
                                                    </tr>


                                                    <tr>
                                                    <td className="product-thumbnail text-left ps-0">
                                                  
                                                            <div className="strong font-xsssss text-gray">
                                                            <Link to="/compose"> {dailyPoints?.posts}/1 #GS_Lyrics#</Link>
                                                                                                                         
                                                              </div> 
                                                              <div className=" font-xsssss text-red">  (200 points, top 10 - 10 GSC, Top 1 - 50 GSC )</div> 
                                                        </td> 
                                                        <td className="product-thumbnail text-left ps-0">
                                                        <span className=" font-xsssss text-gray">200</span> 
                                                            <img src="https://files.geetsuhane.com/home/points.png" alt="GS Points" className="w25 rounded-3" />
                                                            
                                                        </td>                                                   
                                                    </tr>
                                                   
                                                    
                                                    <tr>
                                                    <td className="product-thumbnail text-left ps-0">
                                                            <div className="strong font-xsssss text-gray">
                                                            <Link to="/buzz"> {dailyPoints?.likes}/10 likes</Link>
                                                              </div>
                                                            <div className=" font-xsssss text-red">  (10 points)</div>  
                                                        </td> 
                                                        <td className="product-thumbnail text-left ps-0">
                                                        <span className="strong font-xsssss text-gray">100</span> 
                                                            <img src="https://files.geetsuhane.com/home/points.png" alt="GS Points" className="w25 rounded-3" />
                                                            
                                                        </td>                                                   
                                                      
                                                        {/* <td className="product-total-price">
                                                           
                                                            <button ref={likesRef} onClick={()=>  getCoins(dailyPoints?.likes>=10 && parseInt(rewards?.likes)<10 ?10:0,'likes')} disabled={rewards?.likes>0?'disabled':false} className={`pointer ${rewards?.likes>0?'bg-grey':'bg-gold-gradiant'}  text-white fw-600 text-uppercase font-xsssss  rounded-3 border-size-md  w85 p-2 text-center ls-1`}>{rewards?.likes>0?'Collected':'$Collect$'}</button>

                                                        </td> */}
                                                    </tr>
                                                    <tr>
                                                    <td className="product-thumbnail text-left ps-0">
                                                  
                                                            <div className="strong font-xsssss text-gray">
                                                      <Link to="/buzz">{dailyPoints?.comments}/5 Comments</Link>                                                              
                                                              </div> 
                                                            <div className=" font-xsssss text-red">  (20 points)</div> 
                                                        </td> 
                                                        <td className="product-thumbnail text-left ps-0">
                                                        <span className="strong font-xsssss text-gray">100</span> 
                                                            <img src="https://files.geetsuhane.com/home/points.png" alt="GS Points" className="w25 rounded-3" />
                                                            
                                                        </td>                                                   
                                                  
                                                    </tr>
                                                   
                                                    <tr>
                                                    <td className="product-thumbnail text-left ps-0">
                                                  
                                                            <div className="strong font-xsssss text-gray">
                                                          <Link to="/buzz"> {dailyPoints?.shares}/2 Share</Link>                                                             
                                                              </div> 
                                                            <div className=" font-xsssss text-red">  (50 points)</div> 
                                                        </td> 
                                                        <td className="product-thumbnail text-left ps-0">
                                                        <span className="strong font-xsssss text-gray">100</span> 
                                                            <img src="https://files.geetsuhane.com/home/points.png" alt="GS Points" className="w25 rounded-3" />
                                                            
                                                        </td>                                                   
                                                      
                                                        
                                                    </tr>
                                            

                                                    <tr>
                                                    <td className="product-thumbnail text-left ps-0">
                                                  
                                                            <div className="strong font-xsssss text-gray">
                                                      <Link to="/game">{dailyPoints?.gsp}/10 GSP</Link>
                                                              
                                                              </div>
                                                            <div className=" font-xsssss text-red">  (50 points)</div> 
                                                        </td> 
                                                        <td className="product-thumbnail text-left ps-0">
                                                        <span className="strong font-xsssss text-gray">500
                                                        <img src="https://files.geetsuhane.com/home/points.png" alt="GS Points" className="w25 rounded-3" /></span>  
                                                            
                                                        </td>                                                   
                                                   
                                                    </tr>

                                                    <tr>
                                                    <td className="product-thumbnail text-left ps-0">
                                                  
                                                            <div className="strong font-xsssss text-gray">
                                                        <Link to="/cafe"> {dailyPoints?.gsc}/10 Cafe</Link>                                                             
                                                              </div>
                                                            <div className=" font-xsssss text-red">  (50 points)</div> 
                                                        </td> 
                                                        <td className="product-thumbnail text-left ps-0">
                                                        <span className="strong font-xsssss text-gray">500<img src="https://files.geetsuhane.com/home/points.png" alt="GS Points" className="w25 rounded-3" /></span> 
                                                        
                                                            
                                                        </td>                                                   
                                                   
                                                    </tr>

                                                    <tr>
                                                    <td className="product-thumbnail text-left ps-0">
                                                  
                                                            <div className="strong font-xsssss text-gray">
                                                        <Link to="/chess"> {dailyPoints?.brj}/10 Chess</Link>                                                             
                                                              </div>
                                                            <div className=" font-xsssss text-red">  (2.5K points)</div> 
                                                        </td> 
                                                        <td className="product-thumbnail text-left ps-0">
                                                        <span className="strong font-xsssss text-gray">2.5K<img src="https://files.geetsuhane.com/home/points.png" alt="GS Points" className="w25 rounded-3" /></span>
                                                        
                                                            
                                                        </td>                                                   
                                                   
                                                    </tr>

                                                   
                                                    {/* <tr>
                                                    <td className="product-thumbnail text-left ps-0">
                                                  
                                                            <div className="strong font-xsssss text-gray">
                                                            <Link to="/boatrace"> {dailyPoints?.brp}/1 Boat Race </Link>
                                                              
                                                              </div>
                                                              <div className=" font-xsssss text-red">  (600 points)</div> 
                                                        </td> 
                                                        <td className="product-thumbnail text-left ps-0">
                                                        <span className="strong font-xsssss text-gray">600</span> 
                                                            <img src="https://files.geetsuhane.com/home/points.png" alt="GS Points" className="w25 rounded-3" />
                                                            
                                                        </td>                                                   
                                                   
                                                    </tr> */}
                                                    <tr>
                                                    <td className="product-thumbnail text-left ps-0">
                                                  
                                                            <div className="strong font-xsssss text-gray">
                                                      <Link to="/dw"> {dailyPoints?.dw}/10 Play DW</Link>
                                                             
                                                              </div>
                                                            <div className=" font-xsssss text-red">  (50 points)</div> 
                                                        </td> 
                                                        <td className="product-thumbnail text-left ps-0">
                                                        <span className="strong font-xsssss text-gray">500<img src="https://files.geetsuhane.com/home/points.png" alt="GS Points" className="w25 rounded-3" /></span> 
                                                            
                                                            
                                                        </td>                                                   
                                                   
                                                    </tr>
                                                    <tr>
                                                    <td className="product-thumbnail text-left ps-0">
                                                  
                                                            <div className="strong font-xsssss text-gray">
                                                      <Link to="/dw"> {dailyPoints?.gifts}/5 Send Gifts</Link>
                                                             
                                                              </div>
                                                            <div className=" font-xsssss text-red">  (100 points)</div> 
                                                        </td> 
                                                        <td className="product-thumbnail text-left ps-0">
                                                        <span className="strong font-xsssss text-gray">400<img src="https://files.geetsuhane.com/home/points.png" alt="GS Points" className="w25 rounded-3" /></span> 
                                                           
                                                            
                                                        </td>                                                   
                                                   
                                                    </tr> 
                                                </tbody>
                                            </table>
                                            <div className="text-center font-xsss  text-red strong"> Daily Reward</div>
                                            <div className="text-center">  <span className="text-center font-xsssss  text-grey strong">Top #10 DW, Boat Race Winners and Videos : GSC 10</span> </div>
                                            <div className="text-center">  <span className="text-center font-xsssss  text-grey strong">Top #1 Video,  GSC 50</span> </div>

                                        </div>
                                        )}
                                    </div>
                                  <div>
                                    
                                  </div>

                                </div>
                    <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                      {currentUser?.SID===refid &&
                     <div id="#post">
                      <Youtube />
                    <Createpost />   </div> }
                      
                                  
                    </div>
                    {/* <div>  <Profilephoto /></div> */}
                    <div id="post" className="col-xl-8 col-xxl-9 col-lg-8 ">
                      {/* {currentUser?.SID===userData?.SID && ( */}
                          
                      {/* )

                      } */}
                                       
                      {/* <Load /> */}
                    </div>
                    <div c>
                      {!moments &&
                   <div className="card-body d-block w-100 text-center">                                                                                                                                                   
                            <a onClick={() => loadMorePosts()}
                             className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block  bg-current font-xsssss fw-700 ls-lg text-white text-center"> 
                             Show moments </a>
                    </div>
                      }
                    {posts?.length > 0 && moments &&
                    posts.map((item, index) => {     
                      //console.log(item);
                      return (
                      
                        <React.Fragment key={index}>
                        
                          {/* {number === index ? <Memberslider /> : ""} */}
                          <Postview

                            rid={item?.rid}
                            refname={item?.refname}
                            reftext = {item?.reftext}

                            refpic={item?.refpic?(item.refpic.includes('googleusercontent')?item?.refpic:cdn+'users/'+item?.refpic):""}
                            refuser={item?.refuser?item?.refuser:''}
                            id={item?.id}
                           
                            postimage={item?.mediaType== 'img' ? cdn+'users/'+item?.mediaUrl:'' }
                            // postvideo={item.mediaType== 'video' ? cdn+'users/'+item.mediaUrl:'' }
                            postvideo={item?.mediaType== 'video'?(item?.status===2?ssd+item?.mediaUrl: cdn+'users/'+item?.mediaUrl):'' }
                            avater={item?.picture?(item?.picture.includes('googleusercontent')?item.picture:cdn+'users/'+item?.picture):""}
                            user={item?.name?item?.name:''}
                            time={item?.createdAt}
                            des={item?.text}
                            item={item}
                            setGift={sendGift}
                            giftItem={giftItem}
                          />
                        
                        </React.Fragment>
                      );
                    })}
                    {
                        !more &&
                      <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">                                                                               
                  <a onClick={() => loadMorePosts()}
                   className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block  bg-current font-xsssss fw-700 ls-lg text-white"> 
                   More moments </a>
                  
               </div> }
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          
            <center>   
      
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeDrawer}
        isVisible={isVisible}
      >
        <div className="fw-700 text-grey-900 font-xsssss mt-1"> <img style={{width:"12px",height:"12x"}} src={localStorage.getItem('userpic')}></img> {localStorage.getItem('user')}</div>
       
        
          <Gifts giftItem={giftItem}  setgiftItem = {setgiftItem}></Gifts>
         
          </Drawer>
          </center> 
            {/* <Popupchat /> */}
          </Fragment>
        );
    }